.userListPage {
  flex: 4;
  background:rgba(234, 234, 238, 1);
  padding: 2.5rem 0.625rem;
  border-radius: 1.5rem;
  backdrop-filter: blur(50px);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.4);
  .userListEditButton {
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: transparent;
    color: orange;
    margin-right: 15px;
    cursor: pointer;
  }

  .userListDoneButton {
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: transparent;
    color: green;
    margin-right: 15px;
    cursor: pointer;
  }
  .userListDeleteButton {
    cursor: pointer;
    color: rgb(182, 0, 0);
  }
}

.approved-row {
  background-color: lightgreen;
}