

.login-page {
  padding: 8% 0 0;
  margin: auto;
  background-color: transparent;
  border-radius: 10px;
}

.avatar {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.avatar img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.form {
  position: relative;
  z-index: 1;
  max-width: 480px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  background-color: #EAEAEE;
  border-radius: 20px;
  color:black;
}

.form input[type="text"],
.form input[type="password"] {
  font-family: "Poppins", sans-serif;
  outline: none;
  width: 100%;
  padding: 15px;
  font-size: 14px;
  border-radius: 20px;

  // background-color: rgba(255, 255, 255, 0.7);
}

.loginButton {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: none;
  background-color: #261C36;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 20px;
}

.loginButton:hover,
.loginButton:active,
.loginButton:focus {
  background-color: #1976d2;
}

.form h2 {
  color: black;
  font-size: 28px;
  margin: 0 0 30px;
}

.form p.message {
  color: #666666;
  font-size: 14px;
  margin-top: 15px;
}

.form p.message a {
  color: #2196f3;
  text-decoration: none;
}

.form p.message a:hover {
  text-decoration: underline;
}
