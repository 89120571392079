html {
  max-width: 100%;
  overflow-x: hidden;
}
@import url('https://fonts.googleapis.com/css?family=Poppins:200i,400&display=swap');
body {
  font-family: 'Poppins', sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: black !important;
  margin: 0;
  backdrop-filter: blur(3px);

}

body {
  padding: 0;
  margin: -0.5rem;
  box-sizing: border-box;
  background-attachment: fixed !important;
  background-image: linear-gradient(
    115deg,
    rgba(11, 0, 28, 0.9),
    rgba(11, 0, 28, 0.9)
  ),
  url("../src/images/bg1.jpeg");
  // background: url("../images/bg1.jpeg") center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.container {
  display: grid;
  width: 100%;
  /* height: 250px; */
  grid-template-areas:
    "head"
    "body";
  grid-template-columns: minmax(0, 100%);
  margin: 0 auto;
  overflow: none;
}

.contentHeader {
  position: sticky;
  grid-area: head;
  height: 8vh;
}

.contentBody {
  grid-area: body;
  /* background: black; */
  max-height: calc(100vh - 8vh);
  height: calc(100vh - 8vh);
}
html {
  max-width: 100%;
  overflow-x: hidden;
}

.formContainer {
  background:rgba(234, 234, 238, 1);
  padding: 2.5rem 0.625rem;
  border-radius: 1.5rem;
  backdrop-filter: blur(50px);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.4);
}
